// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let ownedPolzilla = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(
          await store.getState().blockchain.account
        )
        .call();
        
      let ownedPolz = await store
        .getState()
        .blockchain.polzSmartContract.methods.walletOfOwner(
          await store.getState().blockchain.account
        )
        .call();

      let mintedZillas = await store
        .getState()
        .blockchain.smartContract.methods.claimedTokens()
        .call();

      let claimable = ownedPolz.filter((polz) => !ownedPolzilla.includes(polz))
        .filter((polz) => !mintedZillas.includes(polz));

      let canClaimWallet = claimable.length > 0 && ownedPolzilla.length == 0;
      
      console.log({
        totalSupply: totalSupply,
        claimable: claimable,
        ownedPolz: ownedPolz,
        ownedPolzilla: ownedPolzilla,
        canClaimWallet: canClaimWallet,
        mintedZillas: mintedZillas
      });
      
      dispatch(
        fetchDataSuccess({
          totalSupply,
          claimable,
          ownedPolz,
          ownedPolzilla,
          canClaimWallet,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
