import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px 38px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Pangolin', cursive;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 32px;
  color: var(--secondary-text);
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-family: 'Pangolin', cursive;
`;

export const StyledRoundLink = styled.a`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 32px;
  color: var(--secondary-text);
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-family: 'Pangolin', cursive;
  margin: 0 24px;
`;

export const StyledButtonLink = styled.a`
  padding: 20px 38px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Pangolin', cursive;
  text-decoration: none;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledPreview = styled.img`
  width: 42%;
  border-radius: 12px;
  margin-right: 32;
  @media (min-width: 767px) {
    max-width: 240px;
  }
`;

export const StyledClaimPreview = styled.img`
  width: 100%;
  border-radius: 12px;
  @media (min-width: 767px) {
    max-width: 240px;
  }
`;

export const StyledPond = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 800px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  // const [feedback, setFeedback] = useState(`How many Polz are there in your wallet?`);
  const [feedback, setFeedback] = useState('');
  const [mintAmount, setMintAmount] = useState(0);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    MAX_MINT_AMOUNT: 20,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    IPFS: {
      CRYPTOPOLZ_IMAGES: "QmUGTWGEnDapg6pFphN1te3kUzNNmpfLtM1Wg88JmChFue",
      POLZILLA_IMAGES: "QmacYnfwYbp4eUTEKX4yvqeJKfug3NSVz1vMbEJsztxEsn"
    }
  });

  const claimNFTs = (tokenId) => {
    let amount = tokenId ? 1 : data.claimable.length; // mintAmount
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount);
    let totalGasLimit = String(gasLimit * amount);
    console.log("NFTs: ", amount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}${amount > 1 ? 's' : ''}...`);
    setClaimingNft(true);

    if (tokenId) {
      blockchain.smartContract.methods
        .mint(tokenId)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, there's ${amount} new ${CONFIG.NFT_NAME} in your wallet! Go check OpenSea!`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } else {
      blockchain.smartContract.methods
        .claimWallet()
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, there's ${amount} new ${CONFIG.NFT_NAME} in your wallet! Go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "var(--primary)",
          // backgroundImage: "url(/config/images/background.png)", 
          // backgroundSize: "1000px",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "bottom center",
        }}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              // backgroundColor: "var(--accent-semi)",
              // backgroundImage: "url(/config/images/pond.png)", 
              // backgroundSize: "1000px",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "bottom center",
              padding: "24 0",
              borderRadius: 24,
              // border: "3px dashed var(--secondary)",
              // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 42,
                fontWeight: "bold",
                color: "var(--accent-text)",
                marginTop: 42,
              }}
            >
              <span style={{ color: "var(--secondary)" }}>Free mint.</span><br />
              <StyledLink href="https://cryptopolz.com" target="_blank" style={{ color: "var(--yellow)" }}>CryptoPolz</StyledLink> owners only.<br />
            </s.TextTitle>
            <img src="/config/images/bg.gif" />
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  All Polzillas have been minted.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                    <s.SpacerSmall />
                    {CONFIG.NETWORK.NAME == "Rinkeby" &&
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontSize: 20
                        }}
                      >
                        {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                    }
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--yellow)", fontSize: 18 }}
                    >
                      FREE (+ gas)<br />
                    </s.TextTitle>
                    <s.SpacerSmall />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {claimingNft ? (
                      <>
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              color: "var(--accent-text)",
                              fontSize: 24
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                        </s.Container>
                      </>
                    ) : (
                      <>
                        {data.claimable.length > 0 ? (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  alignItems: "center",
                                  color: "var(--accent-text)",
                                  fontSize: 20,
                                  width: "100%"
                                }}
                              >
                                gm fren<br /><br />
                                You currently own {data.ownedPolz.length} CryptoPolz. <br />
                                {data.ownedPolzilla.length > 0 ? (
                                  <>
                                    You have already minted {data.ownedPolzilla.length}
                                    {data.ownedPolzilla.length > 1 ? ` Polzillas` : ` Polzilla`}.</>
                                ) : (
                                  <>You haven't minted any Polzillas yet.</>
                                )} <br />
                                You can mint {data.claimable.length} Polzilla{data.claimable.length > 1 ? 's' : ''}:
                              </s.TextDescription>
                            </s.Container>
                            <s.SpacerLarge />
                            {data.canClaimWallet && (
                              <s.Container ai={"center"} jc={"center"} fd={"column"}>
                                <StyledButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs();
                                    getData();
                                  }}
                                >
                                  {claimingNft ? "MINTING..." : `MINT ALL (${data.claimable.length})`}
                                </StyledButton>
                                <s.SpacerLarge />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    color: "var(--accent-text)",
                                    fontSize: 20,
                                    width: "100%"
                                  }}
                                >
                                  You may mint all your Polzillas at once by hitting the "MINT ALL" button, or mint them individually at any time. <br />
                                </s.TextDescription>
                              </s.Container>
                            )}
                            <s.SpacerLarge />
                            <s.Container ai={"center"} jc={"center"} fd={"column"}>
                              <s.Container jc={"center"} ai={"center"} fd={"column"} style={{ width: "100%" }}>
                                {data.claimable.map((claimable) => (
                                  <s.Container key={claimable} jc={"center"} ai={"center"} fd={"column"} style={{ width: "90%" }}>
                                    <s.Container key={claimable} jc={"center"} ai={"center"} fd={"row"} style={{ width: window.innerWidth < 700 ? "50%" : "100%" }}>
                                      <StyledClaimPreview
                                        src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/${claimable}.png`}
                                        style={{ marginRight: 32 }}
                                      />
                                      <StyledClaimPreview
                                        src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/${claimable}.png`}
                                      />
                                    </s.Container>
                                    <s.SpacerLarge />
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      style={{
                                        fontSize: 16,
                                        padding: "12px 24px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs(claimable);
                                      }}
                                    >
                                      Mint Polzilla #{claimable}
                                    </StyledButton>
                                    <s.SpacerLarge />
                                    <s.SpacerLarge />
                                  </s.Container>
                                ))}
                              </s.Container>
                            </s.Container>
                            <s.SpacerLarge />
                          </>
                        ) : (
                          <>
                            {data.ownedPolzilla.length > 0 ? (
                              <>
                                <s.TextDescription
                                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                  You have minted all your Polzillas!
                                </s.TextDescription>
                                <s.SpacerSmall />
                                <s.SpacerSmall />
                                <StyledButtonLink target={"_blank"} href="https://opensea.io/collection/polzilla">
                                  OpenSea
                                </StyledButtonLink>
                              </>
                            ) : (
                              <>
                                <s.TextDescription
                                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                  Oh no 😢, looks like you don't own any CryptoPolz. If you want to mint a Polzilla you first need to buy a CryptoPolz, click 'OpenSea' button below. Once you have bought one you are more than welcome to try again.
                                </s.TextDescription>
                                <s.SpacerSmall />
                                <s.SpacerSmall />
                                <StyledButtonLink target={"_blank"} href="https://opensea.io/collection/cryptopolz">
                                  OpenSea
                                </StyledButtonLink>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerLarge />
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 42
              }}
            >
              A toxic spill has been released into the pond,
              causing the <StyledLink target={"_blank"} href="https://cryptopolz.com">CryptoPolz</StyledLink>
              &nbsp; to evolve into <span style={{ color: "var(--yellow)" }}>Polzilla</span>.
            </s.TextDescription>

            <StyledPond
              alt={"Pond"}
              src={"/config/images/background.png"}
              style={{
                marginTop: 32,
                width: "200%"
              }}
            />

            <s.TextDescription
              style={{
                marginTop: 64,
                textAlign: "center",
                fontSize: 42
              }}
            >
              <span style={{ color: "var(--yellow)" }}>CryptoPolz</span> have grown <span style={{ color: "var(--secondary)" }}>bigger</span> than expected!
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>
            <s.TextDescription style={{ width: "100%", marginTop: 64, color: "#fff", fontSize: 24, textAlign: "center" }}>
              Most traits were left unchanged...
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/6757.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/6757.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/1648.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/1648.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/9189.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/9189.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/8515.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/8515.png`}
              />
            </s.Container>
            <s.TextDescription style={{ width: "100%", marginTop: 64, color: "#fff", fontSize: 24, textAlign: "center" }}>
              ... But we spiced things up a little.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/6135.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/6135.png`}
              />
            </s.Container>
            <s.TextDescription style={{ width: "100%", marginTop: 32, color: "#fff", fontSize: 24, textAlign: "center" }}>
              Flies are now... Helicopters.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 32 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/1791.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/1791.png`}
              />
            </s.Container>
            <s.TextDescription style={{ width: "100%", marginTop: 64, color: "#fff", fontSize: 24, textAlign: "center" }}>
              The new Radioactive trait replaces Five Flies. It has 2 variants: Green and Blue.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 32 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/4980.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/4980.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 32 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/3956.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/3956.png`}
              />
            </s.Container>

            <s.TextDescription style={{ width: "100%", marginTop: 64, color: "#fff", fontSize: 24, textAlign: "center" }}>
              We kept the original backgrounds, but some would randomly be replaced with new City backgrounds.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 32 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/8595.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/8595.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/7986.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/7986.png`}
              />
            </s.Container>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/1870.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/1870.png`}
              />
            </s.Container>

            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 128 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/5684.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/5684.png`}
              />
            </s.Container>
            <s.TextDescription style={{ width: "100%", marginTop: 32, color: "#fff", fontSize: 24, textAlign: "center" }}>
              Chocolate sometimes evolves into Blood Thirst, a new super rare trait.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", marginTop: 64 }}>
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.CRYPTOPOLZ_IMAGES}/7586.png`}
                style={{ marginRight: 32 }}
              />
              <StyledPreview
                src={`https://ipfs.io/ipfs/${CONFIG.IPFS.POLZILLA_IMAGES}/7586.png`}
              />
            </s.Container>
            <s.TextDescription style={{ width: "100%", marginTop: 32, color: "#fff", fontSize: 24, textAlign: "center" }}>
              If you're super lucky, your Polzilla will have a significant rarity bump like this one.
            </s.TextDescription>

            <s.SpacerLarge />

            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>

            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32
              }}
            >
              Smart Contract<br />
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} style={{ fontSize: 32 }}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>

            <s.SpacerMedium />

            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", margin: "32px 0" }}>
              <a href="https://metapond.io">
                <StyledLogo src="https://metapond.io/images/metapond-logo.png" />
              </a>
            </s.Container>

            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "80%", margin: "32px 0" }}>
              <StyledRoundLink href={CONFIG.MARKETPLACE_LINK} target="_blank">
                <img src="/config/images/opensea.svg" alt="Follow @cryptopolznft on Twitter" />
              </StyledRoundLink>
              <StyledRoundLink href="https://twitter.com/polzillanft" target="_blank">
                <img src="/config/images/twitter.svg" alt="Follow @cryptopolznft on Twitter" />
              </StyledRoundLink>
              <StyledRoundLink href="http://discord.metapond.io" target="_blank">
                <img src="/config/images/discord.svg" alt="Join our Discord server" />
              </StyledRoundLink>
            </s.Container>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
